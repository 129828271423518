import _get from "lodash/get"
import _last from "lodash/last"

export const getPath = (props) => {
  const path = _get(props, "href", "")

  return _last(path.split("/"))
}

export const getParamsId = () => {
  const path =
    typeof window !== "undefined" ? window.location.pathname.split("/") : ""
  const id = path[path.length - 1]

  if (id && !isNaN(Number(id))) {
    return id
  }

  return null
}

export const getParamsUsername = () => {
  const path =
    typeof window !== "undefined" ? window.location.pathname.split("/") : ""
  const username = path[path.length - 1]

  if (username) {
    return username
  }

  return null
}

export const normalizePath = (path = "") => {
  return path.replace(/\s+/g, "-").toLowerCase()
}

export const getWatermarkURL = (mediaPath, user) => {
  if (mediaPath) {
    const token = _get(user, "token", "")
    const mediaType = mediaPath.split("/")[0]
    const typeDir = {
      images: "photo",
      videos: "video",
    }
    const waterMarkURL = `/media/${typeDir[mediaType]}/watermark/`
    const queryParams = `?token=${token}`
    return process.env.WATERMARK_IMAGES + waterMarkURL + mediaPath + queryParams
  }
  return null
}
